.Home .lander {
    padding: 80px 0;
    text-align: center;
}

.Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Home .lander p {
    color: #999;
}

.Home .notes h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.Home .notes p {
    color: #666;
}

.Home .lander div {
    padding-top: 20px;
}
.Home .lander div a:first-child {
    margin-right: 20px;
}

.deleted-note {
    background-color: #f7f7f7;
}

.img-button {
    display: inline-block;
    width: 33%;
    text-align: center;
    position: relative;
}

.img-button:hover {
    background-color: #f5f5f5;
}

.img-button img {
    width: 256px;
}

.img-button .add {
    position: relative;
    top: 84px;
    left: -150px;
    width: 20px;
    font-size: 3em;
    color: #555555;
    margin-left: -40px;
}

.img-button img svg {
    width: 100%;
}