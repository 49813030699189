.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.App .logo {
  height: 50px;
  display: inline;
  margin-top: -15px;
  margin-left: -15px;
}