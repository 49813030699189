.info {
    display: flex;

    border-bottom: 2px solid rgba(192, 192, 192, 0.32);
    margin-bottom: 15px;
}

.url-container {
    width: 100%;
}

.info .qr-code {
    padding: 10px 5px 10px 10px;
}

.info .url-link {
    padding: 10px 10px 10px 5px;
    width: 100%;
}

.send-email {
    padding: 10px 10px 10px 5px;
    width: 100%;
}