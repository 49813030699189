.NewNote form {
    padding-bottom: 15px;
}

.NewNote form textarea {
    min-height: 300px;
    font-size: 24px;
}

.btn-password {
    margin-top: -15px !important;
}

.canvas-container {
    border: 1px solid #ccc;
    border-radius: 4px;
}

.container__content {
    width: 440px;
    max-width: 100%;
    padding: 10px;
    text-align: center;
    border: 1px #fafafa;
}

.container_editor_area {
    tab-size: 4ch;
    min-height: 300px;
    overflow: auto;
    margin: 1.67em 0;
}

.container__editor {
    font-size: inherit;
    font-variant-ligatures: common-ligatures;
    border: solid 1px #ccc;
    border-radius: 4px;
    min-height: 300px;
}

.container__editor:focus, .container__editor:focus-within {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
}

.container__editor textarea {
    outline: 0;
}

.button {
    display: inline-block;
    padding: 0 6px;
    text-decoration: none;
    background: #000;
    color: #fff;
}

.button:hover {
    background: linear-gradient(45deg, #E42B66, #E2433F);
}

/* Syntax highlighting */
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: #90a4ae;
}
.token.punctuation {
    color: #9e9e9e;
}
.namespace {
    opacity: 0.7;
}
.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
    color: #e91e63;
}
.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
    color: #4caf50;
}
.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
    color: #795548;
}
.token.atrule,
.token.attr-value,
.token.keyword {
    color: #3f51b5;
}
.token.function {
    color: #f44336;
}
.token.regex,
.token.important,
.token.variable {
    color: #ff9800;
}
.token.important,
.token.bold {
    font-weight: bold;
}
.token.italic {
    font-style: italic;
}
.token.entity {
    cursor: help;
}